













import { AsyncView } from 'client-website-ts-library/plugins';
import { API, Config } from 'client-website-ts-library/services';
import { Listing } from 'client-website-ts-library/types';
import { Component, Mixins } from 'vue-property-decorator';

import Form from '@/components/Forms/Form.vue';
import { FormConstructorData, FormField } from 'client-website-ts-library/types/Forms';
import { ContextItemType } from 'client-website-ts-library/types/Context';

@Component({
  components: {
    Form,
  },
})
export default class ListingMakeAnOffer extends Mixins(AsyncView) {
  private listing: Listing | null = null;

  private formData: FormConstructorData | null = null;

  loadListing(listingId: string): Promise<Listing> {
    return new Promise<Listing>((resolve, reject) => {
      API.Listings.Get(listingId)
        .then((listing) => {
          this.listing = listing;

          this.formData = new FormConstructorData([
            {
              Type: ContextItemType.Listing,
              Id: this.listing.Id,
            },
          ]);

          resolve(listing);

          this.$emit('updateView');
        })
        .catch(() => {
          reject();
        });
    });
  }

  created() {
    const guidTest = /[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}/;

    const idParam = this.$route.params.id;

    if (guidTest.test(idParam)) {
      this.loadListing(idParam.split('&')[0]);
    } else {
      API.Slugs.Resolve(idParam)
        .then((resolvedSlug) => {
          if (resolvedSlug.Type === 'Listing') {
            this.loadListing(resolvedSlug.Id).then((listing) => {
              if (idParam !== listing.Slug) {
                this.$router.replace(`/make-an-offer/${listing.Slug}`);
              }
            });
          } else {
            this.$router.push('/listing-not-found');
          }
        })
        .catch(() => {
          this.$router.push('/listing-not-found');
        });
    }
  }

  handleFieldInput(data: FormField) {
    const field = document.getElementById(`field_${data.FieldId}`);

    if (field) {
      if (field instanceof HTMLInputElement) {
        const value = field.value;
      } else if (field instanceof HTMLSelectElement) {
        if (field.value === 'NominateASolicitorOther') {
          // Get all elements with the class "hidden"
          const fieldsHidden = document.getElementsByClassName('hidden-solicitor');

          // Use forEach to remove the "hidden" class from each element
          Array.from(fieldsHidden).forEach((hiddenField) => {
            hiddenField.classList.remove('hidden-solicitor');
            hiddenField.classList.add('show-solicitor');
          });
        } else if (field.value === 'AgentsRecommendation') {
          // Get all elements with the class "hidden"
          const fieldsHidden = document.getElementsByClassName('show-solicitor');

          // Use forEach to remove the "hidden" class from each element
          Array.from(fieldsHidden).forEach((hiddenField) => {
            hiddenField.classList.remove('show-solicitor');
            hiddenField.classList.add('hidden-solicitor');
          });
        }

        if (field.value === 'SettlementTimeFrameOther') {
          // Get all elements with the class "hidden"
          const fieldsHidden = document.getElementsByClassName('hidden-settlement');

          // Use forEach to remove the "hidden" class from each element
          Array.from(fieldsHidden).forEach((hiddenField) => {
            hiddenField.classList.remove('hidden-settlement');
            hiddenField.classList.add('show-settlement');
          });
        } else if (field.value === 'Settlement 14 Days'
        || field.value === 'Settlement 28 Days'
        || field.value === 'Settlement 35 Days'
        || field.value === 'Settlement 60 Days') {
          // Get all elements with the class "hidden"
          const fieldsHidden = document.getElementsByClassName('show-settlement');

          // Use forEach to remove the "hidden" class from each element
          Array.from(fieldsHidden).forEach((hiddenField) => {
            hiddenField.classList.remove('show-settlement');
            hiddenField.classList.add('hidden-settlement');
          });
        }
      } else {
        console.log('Field type not supported.');
      }
    }
  }
}
